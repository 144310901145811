import React, { memo, useEffect, useRef, useState } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';
import { defaultHospitalCover, relativeStaticPath } from '../../constants';
import { useLocation } from 'react-router-dom';
import './style.css'

let map = null
let gp = null
let infoContainer = null

const Amap = memo((props) => {

    const location = useLocation()
    const isv2 = (location.pathname || '').includes('/v2')

    const [markers, setMarkers] = useState([])
    // const [geoLocation, setGeoLocation] = useState()
    const [geoProvince, setGeoProvince] = useState()
    const hospitalsRef = useRef()

    useEffect(() => {
        if (!window._AMap) {
            window._AMapSecurityConfig = {
                securityJsCode: '3799d1f2133306ed9a95027a6fa27083',
            }
            AMapLoader.load({
                key: "8bce5a2f093cf17a28424dd885219ce2",
                version: "2.0",
                plugins: ["AMap.ToolBar", "AMap.Geocoder"]
            }).then(amap => {
                window._AMap = amap
                initMap()
            })
        } else {
            initMap()
        }

        // return () => {
        //     if (map) {
        //         map.destroy()
        //     }
        // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        hospitalsRef.current = props.hospitals
        initMarks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.hospitals])

    useEffect(() => {
        if (props.provinceChange && props.provinceChange instanceof Function && geoProvince && !gp) {
            props.provinceChange(geoProvince)
            gp = geoProvince
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoProvince])

    const pBack = () => {
        if (props.provinceChange && props.provinceChange instanceof Function && geoProvince) {
            map.remove(markers)
            setMarkers([])
            map.clearInfoWindow()
            props.provinceChange(geoProvince)
        }
    }

    // useEffect(() => {
    //     openInfoWindow(props.selAt)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.selAt])

    const initMarks = () => {
        const hospitals = hospitalsRef.current
        if (hospitals && hospitals.length === 0 && window._AMap && map && props.currentProvince) {
            const geocoder = new window._AMap.Geocoder();
            geocoder.getLocation(props.currentProvince, function(status, result) {
                if (status === 'complete' && result.geocodes.length) {
                    const lnglat = result.geocodes[0].location
                    map.setCenter(lnglat)
                }
            });
        }
        if (hospitals && hospitals.length >= 0 && window._AMap && map) {
            map.remove(markers)
            map.clearInfoWindow()
            const mars = []
            for (let i = 0; i<hospitals.length; i++) {
                const position = new window._AMap.LngLat(hospitals[i].longitude,hospitals[i].latitude)
                const marker = new window._AMap.Marker({
                    content: props.category === '头痛中心' ? `<img src="${relativeStaticPath('/assets/map-icon.png')}" style="width:24px;height:24px" />` : `<img src="${relativeStaticPath('/assets/headache-icon.png')}" style="width:24px;height:24px" />`,
                    position: position,
                    offset: new window._AMap.Pixel(0, -12)
                });
                marker.on('click', () => {
                    openInfoWindow(i)
                });
                mars.push(marker)
            }
            setMarkers(mars)
            map.add(mars)
            map.setFitView()
            setTimeout(() => {
                map.setFitView()
            }, 500)
        }
    }

    const initMap = () => {
        const toolBar = new window._AMap.ToolBar({
            position: { top: '16px', right: '12px' }
        })
        map = new window._AMap.Map("map-container", { zoom: 13 })
        window.map_ = map
        map.addControl(toolBar)
        initMarks()
        // 使用2.0地图缺省定位
        if (!gp) {
            map.getCity(info => {
                if (info.province === '北京市') {
                    window._AMap.plugin('AMap.Geolocation', () => {
                        let geolocation = new window._AMap.Geolocation({
                            enableHighAccuracy: true,
                            zoomToAccuracy: false,
                            needAddress: true
                        });
                        geolocation.getCurrentPosition((status,result) => {
                            if( status === 'complete' ){
                                setGeoProvince(result.addressComponent.province)
                            }else{
                                console.log('setGeoProvince1')
                                setGeoProvince(info.province)
                            }
                        });
                    })
                } else {
                    console.log('setGeoProvince2')
                    setGeoProvince(info.province)
                }
            })
        }
    }

    const openInfoWindow = (i) => {
        if (!window._AMap || !map || i === -1) return

        map.clearInfoWindow()
        const hospitals = hospitalsRef.current
        const hospital = hospitals[i]
        if (!hospital) return
        const position = new window._AMap.LngLat(hospital.longitude,hospital.latitude)
        infoContainer = new window._AMap.InfoWindow({
            isCustom: true,
            content: createInfoWindow(hospital),
            offset: [0,-15]
        });
        infoContainer.open(map, position)
    }

    const createInfoWindow = (hospitalInfo) => {
        const infoWindow = document.createElement('div')
        infoWindow.className = 'info-container'

        const top = document.createElement('div')
        top.className = 'info-top-container'

        const hospitalImage = document.createElement('img')
        hospitalImage.className = 'info-hospital-image'
        hospitalImage.src = hospitalInfo.bannerImage || defaultHospitalCover

        const hospitalContent = document.createElement('div')
        hospitalContent.className = 'info-hospital-content'
        const hospitalName = document.createElement('div')
        hospitalName.className = 'info-hospital-name'
        hospitalName.innerHTML = hospitalInfo.hospitalName
        const hospitalDesc = document.createElement('div')
        hospitalDesc.className = 'info-hospital-desc'
        hospitalDesc.innerHTML = hospitalInfo.hospitalDesc || ''
        hospitalContent.appendChild(hospitalName)
        hospitalContent.appendChild(hospitalDesc)
        if (hospitalInfo.phone) {
            const hospitalContact = document.createElement('div')
            hospitalContact.className = 'info-hospital-contact-c'
            const hospitalContactImg = document.createElement('img')
            hospitalContactImg.src = relativeStaticPath('/assets/phone.png')
            const hospitalContactText = document.createElement('span')
            hospitalContactText.innerHTML = hospitalInfo.phone || ''
            hospitalContact.appendChild(hospitalContactImg)
            hospitalContact.appendChild(hospitalContactText)
            hospitalContent.appendChild(hospitalContact)
        }
        top.appendChild(hospitalImage)
        top.appendChild(hospitalContent)

        const bottom = document.createElement('div')
        bottom.className = 'info-bottom-container'
        const bottomImg = document.createElement('img')
        bottomImg.src = relativeStaticPath('/assets/address.png')
        const bottomText = document.createElement('span')
        bottomText.innerHTML = hospitalInfo.address
        const bottomBtn = document.createElement('div')
        bottomBtn.innerHTML = '查看详情'
        bottomBtn.onclick = () => {
            toDetail(hospitalInfo.id)
        }
        bottom.appendChild(bottomImg)
        bottom.appendChild(bottomText)
        bottom.appendChild(bottomBtn)

        const infoClose = document.createElement('img')
        infoClose.className = 'info-close'
        infoClose.src = relativeStaticPath('/assets/close_fill.png')
        infoClose.onclick = () => {
            map.clearInfoWindow()
        }

        infoWindow.appendChild(top)
        infoWindow.appendChild(bottom)
        infoWindow.appendChild(infoClose)
        return infoWindow
    }

    const toDetail = (id) => {
        window.location.href = relativeStaticPath(`${isv2 ? '/v2' : ''}/detail/${id}`)
    }

    return (
        <div className='map-container'>
            <img className='map-location' src={relativeStaticPath('/assets/location.png')} alt='' onClick={pBack} />
            <div id='map-container'></div>
            {/* <div className='info-container'>
                <div className='info-top-container'>
                    <img className='info-hospital-image' src='/assets/location.png' alt='' />
                    <div className='info-hospital-content'>
                        <div className='info-hospital-name'>浙江大学医学院附属第…</div>
                        <div className='info-hospital-desc'>中国头痛防控基地及体系建设</div>
                        <div className='info-hospital-contact-c'>
                            <img src='/assets/phone.png' alt='' />
                            0571-87783777
                        </div>
                    </div>
                </div>
                <div className='info-bottom-container'>
                    <img src='/assets/address.png' alt='' />
                    <span>杭州市上城区解放路88号…</span>
                    <div>查看详情</div>
                </div>
            </div> */}
        </div>
    )
});

export default Amap;
