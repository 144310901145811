const env = process.env.REACT_APP_ENV

// const server_prefix = process.env.REACT_APP_SERVER_PREFIX
const server_prefix = env === 'production' ? '' : '/api/pier88/MapBackend'

export const relativeServerPath = (p) => `${server_prefix}${p}`

// export const basename = process.env.REACT_APP_BASENAME
export const basename = env === 'production' ? '' : '/html/pier88/MapFront'

export const relativeStaticPath = (p) => `${basename}${p}`
export const defaultHospitalCover = relativeStaticPath('/assets/hospital.png')