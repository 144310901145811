import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { compose } from 'redux';
import { connect } from 'react-redux';
import Contact from '../../components/Contact/Contact';
import { NavBar, Toast, Dialog, SafeArea, SpinLoading, Modal } from 'antd-mobile'
import { useLocation } from 'react-router-dom';

import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import { getWeChatSignature, queryHospitalInfo } from '../../apis/common';

import './styles.css';
import { defaultHospitalCover, relativeStaticPath } from '../../constants';

const Detail = () => {

  const location = useLocation()
  const isv2 = (location.pathname || '').includes('/v2')

  const {id} = useParams();
  const [hospitalInfo, setHospitalInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [assistantVisible, setAssistantVisible] = useState(false)
  const [locationLoading, setLocationLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    queryHospitalInfo(id, isv2).then(res => {
      setLoading(false)
      if (res.data.code === 200 && res.data.data) {
        setHospitalInfo(res.data.data || {})
      } else {
        Toast.show({ content: res.data.msg || '请求失败，请稍后重试' })
      }
    }).catch(() => setLoading(true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const back = () => {
    window.history.back(-1)
  }

  const assistantAction = () => {
    if (isv2) {
      setAssistantVisible(true)
    } else {
      Dialog.confirm({
        title: '提示',
        content: '请添加客服微信：HeadacheSupport',
        confirmText: '复制',
        onConfirm: async () => {
          navigator.clipboard.writeText('HeadacheSupport').then(() => {
            Toast.show({content: '复制成功'})
          }, () => {
            console.log('Copy failed');
          });
        },
      })
    }
  }

  const openLocation = (fn) => {
    window.wx.openLocation({
      latitude: parseFloat(hospitalInfo.latitude),
      longitude: parseFloat(hospitalInfo.longitude),
      name: hospitalInfo.hospitalName,
      address: hospitalInfo.address,
      scale: 13,
      infoUrl: '',
      complete: () => {
        setLocationLoading(false)
        fn && fn instanceof Function && fn()
      }
    })
  }

  const jumpNavigation = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (isWeixin) {
      setLocationLoading(true)
      // openLocation(res => {
      //   if (res.errMsg === 'openLocation:fail, the permission value is offline verifying') {
          getWeChatSignature(window.location.href).then(res => {
            if (res.data.data) {
              const cData = res.data.data
              window.wx.config({ debug: false, ...cData, jsApiList: ['openLocation'] })
              window.wx.ready(function(){
                openLocation()
              })
            } else {
              setLocationLoading(false)
            }
          })
      //   }
      // })
      // window.wx.openLocation({
      //   latitude: hospitalInfo.latitude,
      //   longitude: hospitalInfo.longitude,
      //   name: hospitalInfo.hospitalName,
      //   address: hospitalInfo.address,
      //   scale: 13,
      //   infoUrl: '',
      //   complete: (res) => {
          
      //   }
      // })
    } else {
      window.location.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${hospitalInfo.latitude},${hospitalInfo.longitude};title:${hospitalInfo.hospitalName};addr:${hospitalInfo.address}&referer=中国头痛地图`
    }
    
    // window.location.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${hospitalInfo.latitude},${hospitalInfo.longitude};title:${hospitalInfo.hospitalName};addr:${hospitalInfo.address}&referer=中国头痛地图`
    // window.wx.config({
    //   debug: true, 
    //   appId: 'wxc2a8666ab1d605b0',
    //   timestamp: 1697035989,
    //   nonceStr: 'a2a493bd-c215-4de3-beb1-f58fa3ea1a88',
    //   signature: '74e449460bf024e6d14539c53b48c839096208a4',
    //   jsApiList: ['openLocation']
    // })
    // window.wx.ready(function(){
    //   window.wx.openLocation({
    //     latitude: hospitalInfo.latitude,
    //     longitude: hospitalInfo.longitude,
    //     name: hospitalInfo.hospitalName,
    //     address: hospitalInfo.address,
    //     scale: 13,
    //     infoUrl: ''
    //   })
    // })
  }

  return (
    <Layout>
      <NavBar onBack={back}>医院详情</NavBar>
      {loading ? <Loader /> : (
        <>
        <div className='detail-container'>
          <div className='detail-top-container'>
            <div className='detail-banner-contaner'>
              <img src={hospitalInfo.bannerImage || defaultHospitalCover} alt='' />
              <span>{hospitalInfo.tag}</span>
            </div>
            <div className='detail-content'>
              <div className='detail-content-name'>{hospitalInfo.hospitalName}</div>
              <div className='detail-content-tags'>
                <span>{hospitalInfo.category}</span>
                {hospitalInfo.isExpertCouncil === '是' && <span className='detail-content-tag'>专家委员会机构</span>}
              </div>
              {hospitalInfo.phone && (
                <div className='detail-content-tags' style={{marginBottom: 0}}>
                  <img className='detail-content-icon' src={relativeStaticPath('/assets/phone.png')} alt='' />
                  <span>{hospitalInfo.phone}</span>
                </div>
              )}
            </div>
          </div>
          <div className='detail-bottom-container'>
            <img className='address-icon' src={relativeStaticPath('/assets/address.png')} alt='' />
            <span>{hospitalInfo.address}</span>
            <div className='detail-bottom-space'></div>
            {locationLoading ? <SpinLoading className='navigation-icon' /> : <img className='navigation-icon' src={relativeStaticPath('/assets/navigation.png')} alt='' onClick={jumpNavigation} />}
            {/* <a href={`https://uri.amap.com/marker?position=${hospitalInfo.longitude},${hospitalInfo.latitude}&name=${hospitalInfo.hospitalName}&src=${window.location.href}`}>
              <img className='navigation-icon' src={relativeStaticPath('/assets/navigation.png')} alt='' />
            </a> */}
            {/* <a href={`https://apis.map.qq.com/uri/v1/marker?marker=coord:${hospitalInfo.latitude},${hospitalInfo.longitude};title:${hospitalInfo.hospitalName};addr:${hospitalInfo.address}&referer=中国头痛地图`}>
              <img className='navigation-icon' src={relativeStaticPath('/assets/navigation.png')} alt='' />
            </a> */}
          </div>
        </div>
        {isv2 && (
          <>
            {hospitalInfo.doctorArr && hospitalInfo.doctorArr.length > 0 && (
              <div className='white-bg'>
                <div className='block-header'>临床医生列表</div>
                <table className='detail-table'>
                  <thead>
                    <tr><th>姓名</th><th>科室</th><th>职称</th></tr>
                  </thead>
                  <tbody>
                    {hospitalInfo.doctorArr && hospitalInfo.doctorArr.map((item, index) => (
                      <tr key={index}>
                        <td className='fixed-width'>{item.name}</td>
                        <td className='fixed-width'>{item.department}</td>
                        <td>{item.occupation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {hospitalInfo.registrationInfo && (
              <div className='white-bg'>
                <div className='block-header'>挂号信息</div>
                <div className='rich-container' dangerouslySetInnerHTML={{__html: hospitalInfo.registrationInfo}}></div>
              </div>
            )}
            {hospitalInfo.introduction && (
              <div className='white-bg'>
                <div className='block-header'>机构简介</div>
                <div className='rich-container' dangerouslySetInnerHTML={{__html: hospitalInfo.introduction}}></div>
              </div>
            )}
          </>
        )}
        {!isv2 && (
          <>
          {hospitalInfo.introduction && (
            <div className='white-bg'>
              <div className='block-header'>机构简介</div>
              <div className='rich-container' dangerouslySetInnerHTML={{__html: hospitalInfo.introduction}}></div>
            </div>
          )}
          {hospitalInfo.doctorArr && hospitalInfo.doctorArr.length > 0 && (
            <div className='white-bg'>
              <div className='block-header'>临床医生列表</div>
              <table className='detail-table'>
                <thead>
                  <tr><th>姓名</th><th>科室</th><th>职称</th></tr>
                </thead>
                <tbody>
                  {hospitalInfo.doctorArr && hospitalInfo.doctorArr.map((item, index) => (
                    <tr key={index}>
                      <td className='fixed-width'>{item.name}</td>
                      <td className='fixed-width'>{item.department}</td>
                      <td>{item.occupation}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          </>
        )}
        <div className='assistant-container' onClick={assistantAction}>
          <img src={relativeStaticPath(isv2 ? '/assets/assistant_v2.png' : '/assets/assistant.png')} alt='' />
          <span>头痛防控小助理</span>
        </div>
        <SafeArea position='bottom' />
        </>
      )}
      <Modal visible={assistantVisible} content={<Contact shouldDismiss={() => setAssistantVisible(false)} />} destroyOnClose={true} closeOnMaskClick={true} onClose={() => setAssistantVisible(false)} className="assitant-modal" header={null} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  // auth: state.auth,
});

export default compose(connect(mapStateToProps))(Detail);
