export const LOGIN_WITH_OAUTH_LOADING = 'LOGIN_WITH_OAUTH_LOADING';
export const LOGIN_WITH_OAUTH_SUCCESS = 'LOGIN_WITH_OAUTH_SUCCESS';
export const LOGIN_WITH_OAUTH_FAIL = 'LOGIN_WITH_OAUTH_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_WITH_EMAIL_LOADING = 'LOGIN_WITH_EMAIL_LOADING';
export const LOGIN_WITH_EMAIL_SUCCESS = 'LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_FAIL = 'LOGIN_WITH_EMAIL_FAIL';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const COMMON_LOADING = 'COMMON_LOADING';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';
export const COMMON_FAIL = 'COMMON_FAIL';
