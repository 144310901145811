import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { relativeStaticPath  } from '../../constants';
import './style.css';

import { showHeadacheMap } from '../../apis/common';
import { Toast } from 'antd-mobile'

const Welcome = (props) => {

    const [data, setData] = useState({})

    useEffect(() => {
        sessionStorage.setItem('welcome', true)
        const chartsEle = document.getElementById("welcome-charts");
        const charts = echarts.init(chartsEle);
        showHeadacheMap().then(res => {
            if (res.data.code === 200 && res.data.data) {
                const hl = res.data.data || {}
                setData(hl)
                if (hl.provinceNameNumberMap) {
                    const provinceNameNumberMap = hl.provinceNameNumberMap || {}
                    const keys = Object.keys(provinceNameNumberMap)
                    const vals = []
                    for (let i=0; i<keys.length; i++) {
                        vals.push(provinceNameNumberMap[keys[i]])
                    }
                    
                    let endPoint = (10 / (keys.length || 1)) * 100
                    if (endPoint > 100) {
                        endPoint = 100
                    }
                    const option = {
                        legend: {},
                        grid: {top: '20px', left: '30px', right: '20px', bottom: '68px'},
                        tooltip: {show: true, trigger: 'axis'},
                        xAxis: {
                            type: 'category',
                            data: keys,
                            axisTick: { show: false },
                            axisLabel: { color: 'rgba(0,0,0,.45)', fontSize: 9, interval: 0 }
                        },
                        yAxis: {
                            type: 'value',
                            splitNumber: 0,
                            splitLine: {lineStyle: {color: 'rgba(0,0,0,.15)',type: 'solid'}},
                            axisLabel: { color: 'rgba(0,0,0,0.45)', fontWeight: 'bold' }
                        },
                        dataZoom: [{
                            type: 'slider',
                            show: true,
                            realtime: true,
                            start: 0,
                            end: endPoint,
                            left: 10,
                            right: 10,
                            bottom: 15,
                            height: '15px',
                            textStyle: { color: '#AAA8A8', fontweight: 'lighter' },
                            brushSelect: false,
                            borderColor: 'none',
                            backgroundColor: 'rgba(29,111,220,.08)',
                            fillerColor: 'rgba(27,140,134,0.1)',
                            handleStyle: { color: '#1D6FDC', borderColor: '#1D6FDC' },
                            // brushStyle: {borderColor: 'rgb(27,140,134)', borderWidth: 1},
                            xAxisIndex: [0, 1]
                        },{
                            type: "inside",
                            zoomOnMouseWheel: true,
                            moveOnMouseMove: true,
                            moveOnMouseWheel: true,
                        }],
                        series: [
                            {
                                data: vals,
                                type: 'bar',
                                barCategoryGap: '30%',
                                barMaxWidth: 17,
                                barMinWith: 17,
                                color: '#1D6FDC',
                                label: {show: true, color: '#666', fontWeight: 'bold', position: 'top'}
                            }
                        ]
                    };
                    charts.setOption(option);
                }
              } else {
                Toast.show({ content: res.data.msg || '请求失败，请稍后重试' })
              }
        })
    }, [])

    const dismissAction = () => {
        if (props.shouldDismiss && props.shouldDismiss instanceof Function) {
            props.shouldDismiss()
        }
    }

    return (
        <div>
        <div className='welcome-map-container'>
            <img className='welcome-china' src={relativeStaticPath('/assets/map-bg.png')} alt='' />
            <div className='welcome-content'>
                {/* <div className='welcome-title'>中国头痛防控地图</div> */}
                <div className='welcome-small'>头痛中心{data.headacheCentreNumber || ''}个</div>
                <div className='welcome-small'>头痛门诊{data.headacheOutpatientNumber || ''}个</div>
            </div>
        </div>
        <div className='welcome-charts-container'>
            <div className='charts-title'>中国头痛防控体系地图</div>
            <div className='charts-subtitle'>遍布全国{data.provinceNumber || ''}个省，{data.headacheCentreNumber || ''}个头痛中心，{data.headacheOutpatientNumber || ''}个头痛门诊</div>
            <div className='charts-container' id='welcome-charts'></div>
            <div className='charts-btn' onClick={dismissAction}>查看附近头痛中心/头痛门诊</div>
        </div>
        </div>
    );
};

export default Welcome;
