import { COMMON_LOADING, COMMON_SUCCESS, COMMON_FAIL } from '../types';
  
  const initialState = {
    isLoading: false,
    error: null,
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case COMMON_LOADING:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case COMMON_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
        };
      case COMMON_FAIL:
        return {
          ...state,
          isLoading: false,
          error: payload.error,
        };
      default:
        return state;
    }
  }
  