import React from 'react';
import { relativeStaticPath  } from '../../constants';
import './style.css';

const Contact = (props) => {

    // const dismissAction = () => {
    //     if (props.shouldDismiss && props.shouldDismiss instanceof Function) {
    //         props.shouldDismiss()
    //     }
    // }

    return (
        <div>
        <div className='qrcode-container'>
            <img src={relativeStaticPath('/assets/qrcode.jpg')} alt='' />
        </div>
        <div className='qrcode-sub'>
            <span>如有</span>
            <span className='qrcode-sub-b'>「头痛就医地图」</span>
            <span>相关问题，请扫码添加微信咨询</span>
        </div>
        </div>
    );
};

export default Contact;
