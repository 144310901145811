import React from 'react';
import { SpinLoading } from 'antd-mobile'

import './styles.css';

const Loader = (props) => {
  return (
    <div className="loader-container loader" {...props}>
      <SpinLoading color='primary' />
    </div>
  );
};

export default Loader;
