import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import Home from './pages/Home/Home';
import Detail from './pages/Detail/Detail';
import NotFound from './pages/NotFound/NotFound';

// import Loader from './components/Loader/Loader';

import { logInUserWithOauth, loadMe } from './store/actions/authActions';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom';
import { basename } from './constants';

const App = ({ logInUserWithOauth, auth, loadMe }) => {
  // useEffect(() => {
  //   loadMe();
  // }, [loadMe]);

  //redosled hookova
  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      logInUserWithOauth(cookieJwt);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
  //     loadMe();
  //   }
  // }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);

  return (
    // <>
    //   {auth.appLoaded ? (
    //     <Switch>
    //       <Route path="/login" component={Login} />
    //       <Route path="/register" component={Register} />
    //       <Route path="/users" component={Users} />
    //       <Route path="/notfound" component={NotFound} />
    //       <Route path="/admin" component={Admin} />
    //       <Route exact path="/:username" component={Profile} />
    //       <Route exact path="/" component={Home} />
    //       <Route component={NotFound} />
    //     </Switch>
    //   ) : (
    //     <Loader />
    //   )}
    // </>
    
    <BrowserRouter basename={basename}>
      <Switch>
        <Route path="/detail/:id" component={Detail} />
        <Route exact path="/" component={Home} />
        <Route path="/v2/detail/:id" component={Detail} />
        <Route exact path="/v2" component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe }))(App);
