import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { List, Image, InfiniteScroll, Tabs, Toast, Picker, SafeArea, Modal, SpinLoading, Dialog } from 'antd-mobile'
import { List as VirtualizedList, AutoSizer, WindowScroller } from 'react-virtualized'
// import { Link } from 'react-router-dom';

import Layout from '../../layout/Layout';
import Amap from '../../components/Amap/Amap';
import Welcome from '../../components/Welcome/Welcome';
import Contact from '../../components/Contact/Contact';
import { queryProvinceNameList, queryHospitalList } from '../../apis/common';

import './styles.css';
import { defaultHospitalCover, relativeStaticPath } from '../../constants';
import { useLocation } from 'react-router-dom';

const mapHeight = 0.88 * window.outerWidth > 500 ? 500 : 0.88 * window.outerWidth

const Home = () => {

  const location = useLocation()
  const isv2 = (location.pathname || '').includes('/v2')

  const [category, setCategory] = useState('头痛中心')
  const [province, setProvince] = useState('')
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [pickData, setPickData] = useState([])
  const [pickVisible, setPickVisible] = useState(false)
  const [pickLoading, setPickLoading] = useState(false)
  const [dataSelectedAt, setDataSelectedAt] = useState(-1)
  const [welcomeVisible, setWelcomeVisible] = useState(false)
  const [assistantVisible, setAssistantVisible] = useState(false)
  const [topSearchVisible, setTopSearchVisible] = useState(false)

  useEffect(() => {
    if (!sessionStorage.getItem('welcome') && !isv2) {
      setWelcomeVisible(true)
    }
    // queryProvinceNameList()
    requestHospitalList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, province])

  useEffect(() => {
    return () => {
      if (window.map_) {
        window.map_.destroy()
      }
    };
  }, [])

  const requestHospitalList = (p = null) => {
    setDataSelectedAt(-1)
    if (p) {
      setProvince(p)
    }
    queryHospitalList(category, p || province, search, isv2).then(res => {
      if (res.data.code === 200 && res.data.data) {
        setData([])
        const hl = res.data.data || []
        if (hl.length > 0) {
          setTimeout(() => {
            setDataSelectedAt(0)
          }, 50)
        }
        setData(hl)
      } else {
        Toast.show({ content: res.data.msg || '请求失败，请稍后重试' })
      }
    })
  }

  async function loadMore() {
    setHasMore(false)
  }

  const rowRenderer = ({index, key, style}) => {
    if (index === 0) {
      return (
        <div className='row-header-container' key='row-header-map'>
          <div className='row-header-map-container'>
            <Amap hospitals={data} provinceChange={p => requestHospitalList(p)} currentProvince={province} selAt={dataSelectedAt} category={category} />
            <div className='search-container'>
              <div className='search-inner'>
                {/* <select className='search-selector' value={province} onChange={e => setProvince(e.target.value)}>
                  {pickData.map(pd => <option value={pd} key={pd}>{pd}</option>)}
                </select> */}
                <div className='search-selector-text' onClick={handlePicker}>{province}</div>
                <img src={relativeStaticPath('/assets/dorpdown.png')} alt='' className='search-selector-image' onClick={handlePicker} />
                <div className='search-space'></div>
                <img src={relativeStaticPath('/assets/search.png')} alt='' className='search-input-image' />
                <input value={search} placeholder='搜索医生、医院' className='search-input' onChange={e => setSearch(e.target.value)} />
                <div className='search-btn' onClick={() => requestHospitalList()}>搜索</div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (index === 1 && !isv2) {
      return (
        <Tabs activeKey={category} onChange={key => setCategory(key)} activeLineMode='fixed' key='row-header-tab' style={{ '--fixed-active-line-width': '30px', '--active-line-height': '3px' }}>
          <Tabs.Tab title='头痛中心' key='头痛中心' />
          <Tabs.Tab title='头痛门诊' key='头痛门诊' />
        </Tabs>
      )
    } else {
      const item = data[index - (isv2 ? 1 : 2)]
      if (!item) return
      return (
        <List.Item key={key} onClick={() => handleSelect(index - (isv2 ? 1 : 2))} arrow={false} className={index === (isv2 ? 1 : 2) ? 'no-border' : ''} style={style} prefix={
          <Image src={item.bannerImage || defaultHospitalCover} style={{ borderRadius: 4 }} fit='cover' width={100} height={75} />
        }>
          <div className='list-item-container'>
            <div className='list-item-title'>{item.hospitalName}</div>
            <div className='list-item-tag'>{item.tag}</div>
            <div className='list-item-address'>
              <img src={relativeStaticPath('/assets/address.png')} alt='' />
              <span>{item.address}</span>
            </div>
            {item.doctorName && (
              <div className='list-item-doctor-container'>
                <span className='bolder'>{item.doctorName}</span>
                <span className='tag-content'>{item.department} {item.occupation}</span>
              </div>
            )}
          </div>
        </List.Item>
      )
    }
  };

  const handleSelect = (index) => {
    setDataSelectedAt(index)
    window.location.href = relativeStaticPath(`${isv2 ? '/v2' : ''}/detail/${data[index].id}`)
  }

  const handlePicker = () => {
    setPickVisible(true)
    setPickLoading(true)
    queryProvinceNameList().then(res => {
      if (res.data.code === 200 && res.data.data) {
        const d = res.data.data || []
        setPickData([d.map(di => {
          return {label: di, value: di}
        })])
        setPickLoading(false)
      } else {
        Toast.show({ content: res.data.msg || '请求失败，请稍后重试' })
        setPickLoading(false)
      }
    }).catch(() => {
      Toast.show({ content: '请求失败，请稍后重试' })
      setPickVisible(false)
      setPickLoading(false)
    })
  }

  const handlePickerConfirm = (value) => {
    setProvince(value[0])
  }

  const rowHeight = ({index}) => {
    if (index === 0) {
      return mapHeight
    } else if (index === 1 && !isv2) {
      return 43
    } else {
      const item = data[index - (isv2 ? 1 : 2)]
      return item.doctorName ? 136 : 100
    }
  }

  const onScroll = ({ scrollTop }) => {
    if (scrollTop >= mapHeight - 60) {
      setTopSearchVisible(true)
    } else {
      setTopSearchVisible(false)
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>加载中</span>
            <SpinLoading />
          </>
        ) : ( data.length > 0 ? <span>--- 我是有底线的 ---</span> : <span className='sorry-tips'>{search ? `您好，您搜索的位置没有找到${isv2 ? '相关信息' : category}，如需咨询，请点击右下角按钮联系客服～` : `您好，您当前的位置没有找到${isv2 ? '相关信息' : category}，如需咨询，请点击右下角按钮联系客服～`}</span>)
        }
      </>
    )
  }

  const assistantAction = () => {
    if (isv2) {
      setAssistantVisible(true)
    } else {
      Dialog.confirm({
        title: '提示',
        content: '请添加客服微信：HeadacheSupport',
        confirmText: '复制',
        onConfirm: async () => {
          navigator.clipboard.writeText('HeadacheSupport').then(() => {
            Toast.show({content: '复制成功'})
          }, () => {
            console.log('Copy failed');
          });
        },
      })
    }
  }

  return (
    <Layout>
      <WindowScroller onScroll={onScroll} >
        {({ height, scrollTop, isScrolling }) => (
          <List style={{'--border-top': 'none', '--border-bottom': 'none'}}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <VirtualizedList
                  autoHeight
                  rowCount={data.length + (isv2 ? 1 : 2)}
                  rowRenderer={rowRenderer}
                  width={width}
                  height={height}
                  rowHeight={rowHeight}
                  overscanRowCount={10}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                />
              )}
            </AutoSizer>
          </List>
        )}
      </WindowScroller>
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
      <div className='top-search-container' style={{display: topSearchVisible?'block':'none'}}>
        <div className='search-container'>
          <div className='search-inner'>
            {/* <select className='search-selector' value={province} onChange={e => setProvince(e.target.value)}>
              {pickData.map(pd => <option value={pd} key={pd}>{pd}</option>)}
            </select> */}
            <div className='search-selector-text' onClick={handlePicker}>{province}</div>
            <img src={relativeStaticPath('/assets/dorpdown.png')} alt='' className='search-selector-image' onClick={handlePicker} />
            <div className='search-space'></div>
            <img src={relativeStaticPath('/assets/search.png')} alt='' className='search-input-image' />
            <input value={search} placeholder='搜索医生、医院' className='search-input' onChange={e => setSearch(e.target.value)} />
            <div className='search-btn' onClick={() => requestHospitalList()}>搜索</div>
          </div>
        </div>
        {!isv2 && (
          <Tabs activeKey={category} onChange={key => setCategory(key)} activeLineMode='fixed' style={{ '--fixed-active-line-width': '30px', '--active-line-height': '3px' }}>
            <Tabs.Tab title='头痛中心' key='头痛中心' />
            <Tabs.Tab title='头痛门诊' key='头痛门诊' />
          </Tabs>
        )}
      </div>
      <img src={relativeStaticPath(isv2 ? '/assets/assistant_v2.png' : '/assets/contactus.png')} alt='' className='contactus' onClick={assistantAction} />
      <SafeArea position='bottom' />
      <Picker loading={pickLoading} columns={pickData} visible={pickVisible} onClose={() => { setPickVisible(false) }} onConfirm={handlePickerConfirm} />
      <Modal visible={welcomeVisible} content={<Welcome shouldDismiss={() => setWelcomeVisible(false)} />} destroyOnClose={true} className="welcome-modal" header={null} />
      <Modal visible={assistantVisible} content={<Contact shouldDismiss={() => setAssistantVisible(false)} />} destroyOnClose={true} closeOnMaskClick={true} onClose={() => setAssistantVisible(false)} className="assitant-modal" header={null} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  // auth: state.auth,
});

export default compose(connect(mapStateToProps))(Home);
